<template>
    <nl-loader v-if="preloading" :show="true" />
    <div v-else>
        <ButtonRouterTo to="/partner/sites" label="Retour aux sites" size="is-small" icon-left="backward" />

        <div class="box">
            <div class="d-flex justify-content-between">
                <b-field grouped group-multiline>
                    <div class="control">
                        <b-taglist attached>
                            <b-tag type="is-warning" size="is-medium">
                                {{ $t('global.preorders') }}
                            </b-tag>
                            <b-tag type="is-dark" size="is-medium">
                                {{ preorders.total }}
                            </b-tag>
                        </b-taglist>
                    </div>
                </b-field>
                <div class="d-flex">
                    <PerPageSelector v-model="per_page" :loading="isLoading" @perPageChange="loadAsyncData" />
                    <TableColumnSelect v-model="availableColumns" />
                </div>
            </div>

            <b-table
                :data="preorders.data"
                :mobile-cards="true"
                :debounce-search="400"
                default-sort-direction="desc"
                paginated
                :total="preorders.total"
                :loading="isLoading"
                default-sort="id"
                :per-page="preorders.per_page"
                backend-sorting
                pagination-rounded
                backend-pagination
                backend-filtering
                @page-change="onPageChange"
                @sort="onSort"
                @filters-change="onFilterChange"
            >
                <b-table-column
                    centered
                    sortable
                    field="id"
                    label="ID"
                    searchable
                    cell-class="align-middle"
                    :visible="availableColumns[0].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span>#{{ props.row.id }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="ID"
                        />
                    </div>
                </b-table-column>
                <b-table-column
                    centered
                    searchable
                    field="status"
                    label="Statut"
                    cell-class="align-middle"
                    :visible="availableColumns[1].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span v-if="props.row.status === 'todo'">
                            <span class="tag is-warning">À faire</span>
                        </span>
                        <span v-else-if="props.row.status === 'in_progress'">
                            <span class="tag is-info">En cours</span>
                        </span>
                        <span v-else-if="props.row.status === 'done'">
                            <span class="tag is-success">Fait</span>
                        </span>
                        <span v-else>
                            <span class="tag is-dark">Erreur</span>
                        </span>
                    </div>
                    <template #searchable="props">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">Tous</option>
                            <option value="todo">À faire</option>
                            <option value="in_progress">En cours</option>
                            <option value="done">Fait</option>
                            <option value="error">Erreur</option>
                        </select>
                    </template>
                </b-table-column>
                <b-table-column
                    field="keyword"
                    label="MC"
                    cell-class="align-middle"
                    searchable
                    :visible="availableColumns[2].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.keyword }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Mot-clé"
                        />
                    </div>
                </b-table-column>
                <b-table-column
                    field="lang"
                    label="Langue"
                    searchable
                    centered
                    sortable
                    cell-class="align-middle"
                    :visible="availableColumns[3].visible"
                >
                    <div slot="default" slot-scope="props">
                        <flag class="rounded shadow-sm" :iso="props.row.lang == 'fr' ? 'fr' : 'us'" />
                    </div>
                    <template #searchable="props">
                        <select v-model="props.filters[props.column.field]" class="form-control form-control-sm">
                            <option :value="undefined">Tous</option>
                            <option value="fr">Français</option>
                            <option value="en">Anglais</option>
                        </select>
                    </template>
                </b-table-column>
                <b-table-column
                    field="words_count"
                    label="NB mots"
                    cell-class="align-middle"
                    sortable
                    :visible="availableColumns[4].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.words_count }}</span>
                    </div>
                </b-table-column>
                <b-table-column
                    centered
                    field="tag_title"
                    label="H1"
                    cell-class="align-middle"
                    searchable
                    :visible="availableColumns[5].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span v-if="props.row.tag_title">{{ props.row.tag_title }}</span>
                        <span v-else>-</span>
                    </div>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="H1"
                        />
                    </div>
                </b-table-column>
                <b-table-column
                    centered
                    field="meta_title"
                    label="Title"
                    cell-class="align-middle"
                    searchable
                    :visible="availableColumns[6].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span v-if="props.row.meta_title">{{ props.row.meta_title }}</span>
                        <span v-else>-</span>
                    </div>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Meta"
                        />
                    </div>
                </b-table-column>
                <b-table-column
                    field="url"
                    label="URL"
                    cell-class="align-middle"
                    searchable
                    :visible="availableColumns[7].visible"
                >
                    <div slot="default" slot-scope="props">
                        <span>{{ props.row.url }}</span>
                    </div>
                    <div slot="searchable" slot-scope="props" class="searchableCol">
                        <input
                            v-model="props.filters[props.column.field]"
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="URL"
                        />
                    </div>
                </b-table-column>
            </b-table>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import PerPageSelector from '../../components/UI/Table/PerPageSelector'
    import TableColumnSelect from '../../components/UI/Table/TableColumnSelect'
    import ButtonRouterTo from '../../components/UI/Button/ButtonRouterTo'

    export default {
        name: 'PartnerSitesPreorders',
        components: { ButtonRouterTo, TableColumnSelect, PerPageSelector },
        title: 'layout.preorders',
        data() {
            return {
                appUrl: process.env.APP_URL,
                preloading: true,
                isLoading: false,
                preorders: {},
                per_page: 25,
                availableColumns: [
                    {
                        label: 'ID'
                    },
                    {
                        label: 'Statut'
                    },
                    {
                        label: 'Mot-clé'
                    },
                    {
                        label: 'Langue'
                    },
                    {
                        label: 'NB mots'
                    },
                    {
                        label: 'H1'
                    },
                    {
                        label: 'Title'
                    },
                    {
                        label: 'URL'
                    }
                ]
            }
        },
        computed: {},
        created() {
            this.getPreorders({
                campaignId: this.$route.query.id,
                sort: '-id'
            })
                .then(response => {
                    this.preorders = response.preorders
                })
                .finally(() => {
                    this.preloading = false
                })
        },
        methods: {
            ...mapActions('preorders', {
                getPreorders: 'getSitePreorder'
            }),
            loadAsyncData() {
                this.isLoading = true

                this.getPreorders({
                    campaignId: this.$route.query.id,
                    sort: this.sort,
                    page: this.page,
                    per_page: this.per_page,
                    filter: this.filters
                })
                    .then(response => {
                        this.preorders = response.preorders
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('toastError', error)
                    })
                    .finally(() => {
                        this.isLoading = false
                        this.exportReady = false
                    })
            },
            onSort(field, order) {
                let sort = field
                this.sortOrder = order

                if (this.sortOrder === 'desc') {
                    this.sort = '-' + sort
                } else {
                    this.sort = sort
                }

                this.loadAsyncData()
            },
            onFilterChange(filter) {
                var filters = {}
                Object.keys(filter).forEach(element => {
                    filters[element] = filter[element]
                })
                this.filters = filters

                this.loadAsyncData()
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            }
        }
    }
</script>

<style></style>
